import React from "react";
import classNames from "classnames";

const defaultClassName = "py-2 px-4 bg-white text-sm text-gray-800 disabled:bg-[#EFEFEF] border-[1px] border-gray-200 rounded-md focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 w-full";
const errorClassName = "!border-error-500";
const Input = React.forwardRef(
  ({ isError=false, className="", ...props }, ref) => {
    return(
      <input ref={ref} {...props} className={classNames(
        ...defaultClassName.split(" "),
        ...className.split(" "),
        {[errorClassName]: isError}
      )} />
    )
  }
);

export default Input;
