import authSlice from "redux/authSlice";
import templateSlice from "redux/templateSlice";
import regulationSlice from "redux/regulationSlice";
import oidcSlice from "redux/oidcSlice";

const rootReducer = {
  oidc: oidcSlice,
  auth: authSlice,
  templates: templateSlice,
  regulations: regulationSlice,
};

export default rootReducer;
